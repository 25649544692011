<template>
	<div class="trends">
		<div class="title">{{ group.name }}</div>
		<!-- Chat -->
		<textarea
			ref="newChat"
			v-model="chatText"
			type="text"
			class="inputChat"
			placeholder="Talk the talk..."
		></textarea><br />
		<button class="goButton" @click="addChat()">Talk the Talk</button>
		<div v-for="chat in chats" :key="chat.id" class='previousChats'><b style="font-size:12px">{{ tinyDate(chat.createdAt) }}</b>
			<b>{{ chat.userName }}</b> - {{ chat.talk }}
		</div>
		<div class="footer">
			<button class="goButton" @click="back()">Back</button>
		</div>
		<div class="footer-spacer" />
		<div class="bottom-spacer" />
	</div>
</template>

<script>

export default {
	name: "GroupChat",
	data() {
		return {
			chatText: "",
			chats: [],
			group: {name: ""}
		};
	},
	created() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.group = await this.$services.getOne('groups', this.$route.params.id);
			this.chats = await this.$services.get(`groups/${this.$route.params.id}/chats`);
		},
		addChat: async function () {
			this.chatText = this.chatText.trim();
			if (this.chatText.length > 0) {
				this.chats = await this.$services.createOne(`groups/${this.group.id}/chat`, {text: this.chatText, limit: 200});
				this.chatText = "";
			}
		},
		tinyDate: function (dt) {
			const date = new Date(dt);
			return `${date.getMonth() + 1}/${date.getDate()} `;
		},
		back() {
			this.$router.back();
		}
	}
}

</script>

<style scoped>
.inputChat {
	margin: 2px 2vw 0px 2vw;
	font-size: 24px;
	padding: 3px;
	border-radius: 5px;
	width: 94vw;
	color: black;
}

.previousChats {
	font-size: 14px;
	margin: 5px;
}
</style>
